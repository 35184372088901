<template>
  <div class="MemolanguageView">
    <!-- Memolanguage Container -->
    <router-view class="content" />
    <!-- <div class="memolanguageRoot"> -->
    <!-- <memolanguage-root class="memolanguageRoot"></memolanguage-root> -->
    <!-- </div> -->
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.MemolanguageView {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #171e36;
    // background-color: #444;
}

.content {
    font-size: 4vw;

    background-size: cover;
    background-position: center;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    overflow: hidden;
}

// ------------------------------------------------
// Responsive sizes
// ------------------------------------------------
$maxWidthOnDesktop: 380px;

@include media('>=mobile') {
    .content {
        font-size: $maxWidthOnDesktop / 100 * 4;
    }
}
@include media('>=mobile') {
    .content {
        max-width: $maxWidthOnDesktop;
        max-height: $maxWidthOnDesktop * 1.77;
        margin: auto;
    }
}
</style>
